.App {
  font-family: sans-serif;
  text-align: center;
}

.box_item, .item_list-value {
  display: flex;
}

.box_item-value {
  display: block;
  text-align: center;
  margin: 20px;
}

.item_list-item{
  display: flex;
  flex-direction: column;
}
.table_list {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table_list td, .table_list th {
  border: 1px solid #ddd;
  padding-top: 8px;
}
.table_list-title{
  background-color: #04AA6D;
}



.table_list th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  width: 25%;
  min-width: 25%;
}

.box_item {
  padding-top: 20px;
}